/**
 * Component responsible for rendering a collection of cards with a map.
 * Used on various pages - Town Map, Collections, Booking Results, Hotels, Villas
 *
 */

import styles from "../../styles/partials/_results-block.scss";
import {
  _class,
  centerCoordinates,
  capitalize,
  getQueryParams,
} from "../utilities/helpers";
import ContentProvider from "../containers/ContentProvider";
import ResultCard from "./ResultCard";
import * as SVG from "../widgets/SVG";
import ResultsFilters from "./ResultsFilters";
import Tabs from "./Tabs";
import Transition from "../widgets/Transition";
import Modal from "../widgets/Modal";
import ImageCards from "../partials/ImageCards";

// import image from "../../images/lc_town_map_3.svg";
import image from "../../images/lc_town_map_7.svg";
import LeafletMap from "./LeafletMap";
import zenscroll from "zenscroll";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";

const cl = _class(styles, "results_block");

class ResultsBlock extends React.Component {
  static propTypes = {
    CONTENT: PropTypes.object,
    resultCards: PropTypes.array,
    imageCards: PropTypes.array,
    tabs: PropTypes.arrayOf(PropTypes.oneOf(["results", "children"])),
    view: PropTypes.string,
    page: PropTypes.object,
    filters: PropTypes.arrayOf(
      PropTypes.oneOf(["View", "Price", "People", "Bedrooms", "MoreFilters"])
    ),
    checkboxOptions: PropTypes.arrayOf(
      PropTypes.oneOf(["Amenities", "Collections", "Accommodations"])
    ),
    onImageCardClick: PropTypes.func,
    lang: PropTypes.string,
  };

  static defaultProps = {
    resultCards: [],
    imageCards: [],
    view: "list",
    filters: [],
    checkboxOptions: [],
  };

  static contextTypes = {
    changeRoute: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.LANG = new Translation(props.lang);
    this.googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;

    this.ALL = { label: this.LANG("All"), value: this.LANG("All") };

    this.icons = {
      Price: SVG.currency,
      Bedrooms: SVG.bed,
      MoreFilters: SVG.sliders,
      Map: SVG.map,
      List: SVG.list,
    };

    this.coordinates = centerCoordinates(props.CONTENT.options);

    this.state = {
      view: props.view, // "map",
      tab: {
        [this.ALL.value]: this.ALL,
      },
      nextTab: this.ALL,
      filteredCards: props.resultCards, //this.filterCards(this.tabFilter(props.activeTab), {}),
      visible: true,
      animating: false,
      selectedAttribute: null,
      mapModalOpen: false,
      activeFilters: {},
      tabs: this.createTabs(),
      activeCardId: null,
    };
  }

  componentDidMount() {
    this.createScroller();
    this.setActiveTab();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.animating && !this.state.animating) {
      this.scroller && this.scroller.toY(0, 0);
      this.animateIn();
    }

    if (this.props.resultCards.length !== prevProps.resultCards.length) {
      this.scroller && this.scroller.toY(0, 0);
      this.animateIn();
    }
  }

  /**
   * Set the active tab and scroll down to results section
   *
   * Property query param is used for hotels and villas
   * Page template is used for collections_detail page
   */
  setActiveTab = () => {
    const params = getQueryParams(window.location.search, { property: "" });
    let value = "";

    if (params.property) {
      value = params.property.toLowerCase();
    }

    const path = window.location.pathname;
    const page = this.props.CONTENT.byPath(path);

    if (page && page.template === "collection_detail") {
      value = page.h1;
    }

    const tab = this.state.tabs.find(
      (tab) => tab.value.toLowerCase() === value.toLowerCase()
    );

    if (tab) {
      this.onTabClick(tab);
      zenscroll.to(document.getElementById("results_wrapper"), 1000);
    }
  };

  /**
   * Creates a scroller for the cards column
   */
  createScroller = () => {
    setTimeout(() => {
      const cards = document.getElementById("cards");
      this.scroller = zenscroll.createScroller(cards, 500, 30);
    }, 500);
  };

  /**
   * Scrolls a card into view by id
   *
   * @param  {number} id       id of the card
   */
  handleMarkerClick = (id) => {
    const target = document.getElementById(`card_${id}`);
    target && this.scroller.to(target);
  };

  /**
   * Updates the count on tabs on the booking results page
   *
   * @param   {object} filters       Object of active filters
   * @return  {array}                Updated list of tabs, with item counts
   */
  updateTabs = (filters) => {
    const { tabs, resultCards } = this.props;

    if (tabs === "results") {
      // filter results based on active filters
      const results = this.filterCards(resultCards, filters);

      // get all tabs
      const base = this.props.resultCards.reduce((sum, item) => {
        sum[item.category] = 0;
        return sum;
      }, {});

      // assign count to each tab
      const _tabs = results.reduce((sum, item) => {
        sum[item.category] = sum[item.category] + 1;
        return sum;
      }, base);

      const formatted = Object.keys(_tabs).reduce((sum, key) => {
        if (_tabs[key] !== 0) {
          sum[key] = _tabs[key];
        }

        return sum;
      }, {});

      // add label for each tab
      return Object.keys(formatted).map((tab) => {
        const count = formatted[tab];

        const name = tab
          .split("_")
          .map((w) => capitalize(w))
          .join(" ");

        return {
          label: `${count} ${name}${count !== 1 ? "s" : ""}`,
          value: name,
        };
      });
    }

    return this.state.tabs;
  };

  /**
   * Creates a list of tabs using the current page's children.
   * If in results mode, we'll use an object so we can enable more
   * than one tab at a time.
   *
   * @return  {array}                Array of tabs
   * @return  {array}                Object of tabs
   */
  createTabs = () => {
    const { CONTENT, tabs, page } = this.props;

    if (tabs === "results") {
      return this.updateTabs({});
    }

    return CONTENT.childrenById(page.id).map((child) => ({
      label: child.h1,
      value: child.h1,
    }));
  };

  /**
   * Handles click event on an Image Card (top of ResultsBlock).
   * Starts filtering cards based on card value.
   * Animate out collection of cards
   *
   * @param   {object} card       Object of active filters
   */
  onImageCardClick = (card) => {
    if (this.props.onImageCardClick) {
      this.props.onImageCardClick(card);
    } else {
      this.context.changeRoute(card.path);
    }

    this.setState({
      visible: false,
      animating: true,
      selectedAttribute: "tab",
      nextTab: { label: card.linktitle, value: card.linktitle },
    });
  };

  /**
   * Callback function for Transition - called on done.
   */
  onComplete = () => this.setState({ animating: false });

  /**
   * Animates in the current collection of cards.
   * First we filter the cards to generate a new collection,
   * then make them visible (animation)
   *
   */
  animateIn = () => {
    const { nextTab, tab, activeFilters, tabs, selectedAttribute } = this.state;

    let newTab = {
      ...this.state.tab,
    };

    // only filter tabs if we've clicked on a tab or image filter
    if (selectedAttribute === "tab") {
      // if the next tab is this.ALL, we can show all results
      if (nextTab.value === this.ALL.value) {
        newTab = {
          [this.ALL.value]: this.ALL,
        };
      } else {
        // otherwise, remove the this.ALL tab
        delete newTab[this.ALL.value];

        // if we clicked on a tab that's already active, remove it
        if (newTab[nextTab.value]) {
          delete newTab[nextTab.value];
        } else {
          newTab[nextTab.value] = nextTab;
        }
      }

      // if we unselect all tabs, set this.ALL as the fallback
      if (!Object.keys(newTab).length) {
        newTab = {
          [this.ALL.value]: this.ALL,
        };
      }
    }

    const filteredCards = this.filterCards(
      this.tabFilter(newTab),
      activeFilters
    );

    this.setState({
      tab: newTab,
      visible: true,
      filteredCards,
      tabs: tab[nextTab.value] ? this.updateTabs(activeFilters) : tabs,
    });
  };

  /**
   * Handles click event for a tab.
   * Animate out the current collection of cards
   * Kicks off filtering process
   *
   * @param   {object} nextTab       Object of { label, value }
   */
  onTabClick = (nextTab) =>
    this.setState({
      visible: false,
      animating: true,
      selectedAttribute: "tab",
      nextTab,
    });

  /**
   * Prop for ResultsFilters. Applies new changes to card collection after a filter is selected.
   *
   * @param   {object} card              card
   * @param   {object} filters           filters to use on the current card
   *
   * @return  {array}                    collection of filtered cards
   */
  applyFilters = (activeFilters) => {
    this.setState({
      visible: false,
      animating: true,
      selectedAttribute: "filter",
      activeFilters,
    });
  };

  /**
   * Switches the view between Map and List.
   *
   * @param   {string} field        filter field
   * @param   {string} value        filter value
   */
  onToggleFilter = (field, value) => {
    if (field === "View") {
      this.setState({ view: value });
    }
  };

  /**
   * Shows or hides the map view modal
   *
   */
  toggleMapViewModal = () =>
    this.setState({ mapViewModalOpen: !this.state.mapViewModalOpen });

  /**
   * Helper function for matching bedroom filter
   *
   * @param   {object} card            card
   * @param   {number} Bedrooms        number of bedroms
   *
   * @return  {boolean}
   */
  matchesBedrooms = (card, Bedrooms) => {
    if (Bedrooms === 0) {
      return true;
    }

    return Bedrooms ? parseInt(card.bedrooms) >= Bedrooms : true;
  };

  /**
   * Helper function for matching price filter
   *
   * @param   {object} card            card
   * @param   {number} Minprice        minimum price
   * @param   {number} MaxPrice        maximum price
   *
   * @return  {boolean}
   */
  matchesPrice = (card, MinPrice, MaxPrice) => {
    if (!card.price) {
      return true;
    }

    const price = parseInt(card.price);

    if (MinPrice === "" || MaxPrice === "") {
      return true;
    }

    return price >= MinPrice && price <= MaxPrice;
  };

  /**
   * Helper function for matching bedroom filter. All checkboxes
   *
   * @param   {object} card              card
   * @param   {object} MoreFilters       object of MoreFilters - Amenities, Collections, Accommodation Types
   *
   * @return  {boolean}
   */
  matchesMoreFilters = (card, MoreFilters) => {
    let matchesMoreFilters = true;

    if (MoreFilters) {
      Object.keys(MoreFilters).forEach((key) => {
        const obj = MoreFilters[key];

        Object.keys(obj).forEach((attr) => {
          let value = obj[attr];
          if (
            key === "Views" &&
            value &&
            !card.amenities.find((a) => a.Description === attr)
          ) {
            matchesMoreFilters = false;
          }

          if (
            key === "Amenities" &&
            value &&
            !card.amenities.find((a) => a.Description === attr)
          ) {
            matchesMoreFilters = false;
          }

          if (
            key === "Collections" &&
            value &&
            !card.collections.find((id) => {
              return attr === this.props.CONTENT.byId(id).h1;
            })
          ) {
            matchesMoreFilters = false;
          }

          if (key === "Accommodation Type" && value && card.type !== attr) {
            matchesMoreFilters = obj[card.type];
          }
        });
      });
    }

    return matchesMoreFilters;
  };

  /**
   * Filters cards by the selected tabs
   *
   * @param   {object} tab              Object of tabs, eg {All: { label, value }}
   *
   * @return  {array}                   collection of filtered cards
   */
  tabFilter = (tabs) => {
    const { resultCards } = this.props;

    if (tabs[this.ALL.value]) {
      return resultCards;
    }

    return resultCards.filter((card) => {
      if (typeof card.category === "string") {
        return tabs[this.LANG(card.category)];
      }

      // for collections page - room can belong to multiple categories
      // if there's at least one matching category, return true
      return !!(card.category || []).find((cat) => {
        return tabs[this.LANG(cat)];
      });
    });
  };

  /**
   * Master function for filtering cards by non tab filters
   *
   * @param   {object} card              card
   * @param   {object} filters           filters to use on the current card
   *
   * @return  {array}                    collection of filtered cards
   */
  filterCards = (
    cards,
    { Bedrooms, MinPrice, MaxPrice, MoreFilters, Sort }
  ) => {
    const filtered = cards.reduce((sum, card) => {
      const matchesBedrooms = this.matchesBedrooms(card, Bedrooms);
      const matchesPrice = this.matchesPrice(card, MinPrice, MaxPrice);
      const matchesMoreFilters = this.matchesMoreFilters(card, MoreFilters);

      if (matchesBedrooms && matchesPrice && matchesMoreFilters) {
        sum.push(card);
      }

      return sum;
    }, []);

    if (!Sort) {
      return filtered;
    }

    return filtered.sort((a, b) => {
      if (Sort === "bedrooms_lh")
        return parseInt(a.bedrooms) - parseInt(b.bedrooms);
      if (Sort === "bedrooms_hl")
        return parseInt(b.bedrooms) - parseInt(a.bedrooms);
      if (Sort === "price_lh") return parseInt(a.price) - parseInt(b.price);
      if (Sort === "price_hl") return parseInt(b.price) - parseInt(a.price);
    });
  };

  resultsErrorMessage = () => {
    if (!this.props.resultCards.length) {
      return this.LANG("noAvailabilities");
    }

    return this.LANG("noResults");
  };
  // RENDER METHODS

  renderCards = (view) => {
    const { filteredCards, visible } = this.state;
    return (
      <Transition
        appear
        visible={visible}
        mode="stagger"
        staggerClassName={cl(view + "__list__item")}
        properties={{
          y: ["50%", "0%"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
        onComplete={this.onComplete}
      >
        <ul
          className={cl(view + "__list")}
          ref={(r) => (this.cards = r)}
          id="cards"
          onMouseLeave={() => this.setState({ activeCardId: null })}
        >
          {!filteredCards.length && (
            <li className={cl(view + "__list__item")}>
              <p>{this.resultsErrorMessage()}</p>
            </li>
          )}
          {filteredCards.map((card) => (
            <li
              className={cl(view + "__list__item")}
              key={card.id}
              id={`card_${card.id}`}
              onMouseOver={() => this.setState({ activeCardId: card.id })}
            >
              <ResultCard {...card} small={view === "map_view"} />
            </li>
          ))}
        </ul>
      </Transition>
    );
  };

  renderListView = () => this.renderCards("list_view");

  renderMap = (className) => (
    <LeafletMap
      className={cl(className)}
      image={image}
      markers={this.state.filteredCards}
      onMarkerClick={this.handleMarkerClick}
      activeId={this.state.activeCardId}
    />
  );

  renderMapView = () => (
    <div className={cl("map_view")}>
      {this.renderCards("map_view")}
      {this.renderMap("map_view__map")}
    </div>
  );

  renderTabs = () => (
    <div className={cl("options__tabs")}>
      <Tabs
        onClick={this.onTabClick}
        tabs={this.state.tabs}
        active={this.state.tab}
        fixedWidth
        lang={this.props.lang}
      />
    </div>
  );

  renderFilters = () => {
    if (this.props.resultCards.length && this.props.filters.length) {
      return (
        <div className={cl("options__filters")}>
          <ResultsFilters
            filters={this.props.filters}
            activeView={this.state.view}
            onApply={this.applyFilters}
            onToggle={this.onToggleFilter}
            cards={this.props.resultCards}
            checkboxOptions={this.props.checkboxOptions}
          />
        </div>
      );
    }
  };

  renderMapViewModal = () => {
    return (
      <Modal
        open={this.state.mapViewModalOpen}
        close={this.toggleMapViewModal}
        background="white"
        className={cl("map_modal")}
      >
        <div className={cl("map_view_mobile")}>
          {this.renderMap("map_view_mobile__map")}
          <div className={cl("options")}>
            {this.renderTabs()}
            {this.renderFilters()}
          </div>
          {this.renderCards("map_view_mobile")}
        </div>
      </Modal>
    );
  };

  renderMapToggle = () => {
    return (
      this.props.filters.includes("View") && (
        <div className={cl("map_toggle")} onClick={this.toggleMapViewModal}>
          {SVG.map}
        </div>
      )
    );
  };

  render() {
    const { view } = this.state;

    return (
      <div className={cl("")}>
        {this.renderMapViewModal()}

        <ImageCards
          cards={this.props.imageCards}
          onClick={this.onImageCardClick}
        />

        <div className={cl("options")}>
          {this.renderTabs()}
          {this.renderFilters()}
        </div>

        {this.renderMapToggle()}
        <div className={cl("results")} id="results_wrapper">
          {view === "list" ? this.renderListView() : this.renderMapView()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default ContentProvider(connect(mapStateToProps)(ResultsBlock));
